import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import loadable from "@loadable/component";
import my_json_object from "./json_requests_responses/txn_data_res.json";
const ReactJson = loadable(() => import("react-json-view"));
const TxnApiDemoV2 = () => {
  return (
    <Row className="">
      <Col sm="10" md="10" lg="10" style={{ margin: "auto", maxWidth: "50em" }}>
        <div className="fakeMenu">
          <div className="fakeButtons fakeClose"></div>
          <div className="fakeButtons fakeMinimize"></div>
          <div className="fakeButtons fakeZoom"></div>
        </div>
        <div className="fakeScreen">
          <ReactJson
            src={my_json_object}
            name={"response"}
            theme={"monokai"}
            enableClipboard={false}
            displayDataTypes={false}
            displayObjectSize={false}
          />
        </div>
        <div className="fakeScreenFooter">
          <div className="screenLanguage">json</div>
        </div>
      </Col>
    </Row>
  );
};

export default TxnApiDemoV2;
